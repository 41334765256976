import PageLayout from "@components/layouts/PageLayout";
import {ActiveClasses, ActiveOrigins, Classes, Items, LevelMatrix, Origins, Team, Tiers} from "@components/modules/tft";

import champions from 'data/tft/champions.json';
import classes from 'data/tft/classes.json';
import origins from 'data/tft/origins.json';
import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

interface Props {
  selects: ITftChampion[];
  onSelect: (champion: ITftChampion | ITftChampion[]) => unknown;
  onReset: () => unknown;
}

const TftSimulatorTemplate = ({selects, onSelect, onReset}: Props) => (
  <PageLayout>
    <Title>Tft シナジーシミュレーター</Title>
    <Box>
      <Tiers champions={champions} selects={selects} onClick={onSelect}/>
      <Origins champions={champions} selects={selects} onSelect={onSelect}/>
      <Classes champions={champions} selects={selects} onSelect={onSelect}/>
      <Main>
        <Team champions={selects} onSelect={onSelect} onReset={onReset}/>
        <Active>
          <ActiveOrigins origins={origins} selects={selects}/>
          <ActiveClasses classes={classes} selects={selects}/>
        </Active>
        <LevelMatrix/>
        <Items/>
      </Main>
    </Box>
  </PageLayout>);
export default TftSimulatorTemplate;

const Title = styled.h2`
display: none;
`;

const Box = styled.div`
display: grid;
grid-template-rows: minmax(100vh, 1fr);
grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr) 810px;
grid-column-gap: 10px;
padding: 0 10px;
${media.lessThan("medium")`
  grid-template-columns: 100vw;
  grid-template-rows: repeat(auto-fit, 1fr);
  grid-row-gap: 10px;
`}
`;

const Main = styled.div`
display: grid;
grid-template-rows: min-content min-content min-content min-content;
grid-template-columns: 1fr;
grid-gap: 10px;
${media.lessThan("medium")`
  order: -1;
`}
`;

const Active = styled.div`
display: grid;
grid-template-rows: 1fr;
grid-template-columns: 395px 395px;
grid-column-gap: 20px;
${media.lessThan("medium")`
  grid-template-columns: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 10px;
  justify-content: center;
`}
`;
