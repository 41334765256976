import ReactTooltip from 'react-tooltip';
import styled from "styled-components";

const Tooltip = styled(ReactTooltip)`
max-width: 450px;
padding: 5px;
border-radius: 0;
&.__react_component_tooltip.type-dark{
color: #a88d74;
background-color: #041427;
}
&.__react_component_tooltip.type-dark.show{
opacity: 1;
}
&.__react_component_tooltip.type-dark.border {
border: 1px solid #a88d74;
}
&.__react_component_tooltip.type-dark.border.place-top:before{
border-top: 8px solid #a88d74;
}
&.__react_component_tooltip.type-dark.border.place-right:before{
border-right: 8px solid #a88d74;
}
&.__react_component_tooltip.type-dark.border.place-bottom:before{
border-bottom: 8px solid #a88d74;
}
&.__react_component_tooltip.type-dark.border.place-left:before{
border-left: 8px solid #a88d74;
}
`;

export default Tooltip;
