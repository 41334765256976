import ChampionFilterFilter from "@components/modules/tft/ChampionFilter/ChampionFilterFilter";
import React from 'react';
import styled from "styled-components";

interface Props {
  tiers: IFilter[];
  origins: IFilter[];
  classes: IFilter[];
  onClick: (type: FilterType, id: string) => unknown;
  onReset: () => unknown;
}

export enum FilterType {
  Tier, Class, Origin
}

export default ({tiers, classes, origins, onClick, onReset}: Props) => <Box>
  <Header>フィルター</Header>
  <Content>
    <Reset onClick={onReset}>リセット</Reset>
    <ChampionFilterFilter text="ティアー" filters={tiers} onClick={(id: string) => onClick(FilterType.Tier, id)}/>
    <ChampionFilterFilter text="クラス" filters={classes} onClick={(id: string) => onClick(FilterType.Class, id)}/>
    <ChampionFilterFilter text="オリジン" filters={origins} onClick={(id: string) => onClick(FilterType.Origin, id)}/>
  </Content>
</Box>;

const Box = styled.div`
padding: 0 5px 5px;
`;

const Content = styled.div`
display: grid;
grid-template-rows: repeat(auto-fit);
grid-template-columns: 1fr;
grid-row-gap: 5px;
`;

const Header = styled.p`
margin-bottom : 5px;
font-size : 20px;
`;

const Reset = styled.button`
border: 1px solid #a88d74;
`;
