import StretchLayout from "@components/layouts/StretchLayout";
import TftChampionDetail from "@components/modules/tft/ChampionDetail";
import NotFoundTemplate from "@components/templates/NotFoundTemplate";
import React, {FC} from "react";
import championData from "../../../data/tft/champions.json";

export interface TftChampionDetailTemplateProps {
  id: string;
}

export const TftChampionDetailTemplate: FC<TftChampionDetailTemplateProps> = ({id}) => {
  const champion = championData.find(data => data.id === id);
  return <StretchLayout>
    {champion ? <TftChampionDetail {...champion}/> : <NotFoundTemplate/>}
  </StretchLayout>
};
