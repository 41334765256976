import ItemEffects from "@components/modules/tft/ItemEffects";
import React from 'react';
import styled from "styled-components";
import Tooltip from '../common/Tooltip';
import ItemRecipeBox from './ItemRecipeBox';

interface Props {
  item: ITftItem;
  size?: number;
  tooltip?: boolean;
  disable?: boolean;
}

const Item = ({item, size = 30, tooltip = true, disable = false}: Props) => (
  <>
    <TooltipTooltip id={item.id} effect="solid" border>
      <TooltipTitle>{item.name}</TooltipTitle>
      <TooltipEffects effects={item.effects}/>
      <TooltipDescription>{item.description}</TooltipDescription>
      <TooltipRecipeBox item={item}/>
    </TooltipTooltip>
    <Img
      data-tip
      data-for={item.id}
      data-tip-disable={!tooltip}
      size={size}
      disable={disable}
      src={`/assets/tft/items/${item.id}.png`}
      alt={item.name}/>
  </>
);
export default Item;

const Img = styled.img<{ size: number, disable: boolean }>`
width: ${({size}) => `${size}px`};
height: ${({size}) => `${size}px`};
object-fit: contain;
border-radius: ${({size}) => `${size / 5}px`};
opacity: ${({disable}) => disable ? '0.2' : '1'};
`;

const TooltipTooltip = styled(Tooltip)`
max-width: 300px;
text-align: left;
`;

const TooltipTitle = styled.h4`
margin-bottom: 5px;
`;

const TooltipEffects = styled(ItemEffects)`
margin-bottom: 5px;
`;

const TooltipDescription = styled.p`
margin-bottom: 5px;
`;

const TooltipRecipeBox = styled(ItemRecipeBox)`
justify-content: flex-start;
`;
