import Item from "@components/modules/tft/Item";
import React, {FC} from 'react';
import styled from "styled-components";

const variable = {
  itemSize: 50
};

interface Props {
  filters: IFilter<ITftItem>[];
  onClick?: (id: string) => unknown;
}

const ItemFilter: FC<Props> = ({filters, onClick = (_id) => {}}) => <Box>
  <div>
    <Header>基礎アイテム</Header>
    <BasicList>
      {filters.filter(item => item.value.recipes.length === 0).map(
        item => <ItemButton key={item.id} size={variable.itemSize} onClick={() => onClick(item.id)}>
          <Item item={item.value} size={variable.itemSize} disable={!item.active}/>
        </ItemButton>)}
    </BasicList>
  </div>
  <CraftBox>
    <Header>作成アイテム</Header>
    <CraftList>
      {filters.filter(item => item.value.recipes.length > 0).map(
        item => <ItemButton key={item.id} size={variable.itemSize} onClick={() => onClick(item.id)}>
          <Item item={item.value} size={variable.itemSize} disable={!item.active}/>
        </ItemButton>)}
    </CraftList>
  </CraftBox>
</Box>;

export default ItemFilter;

const Box = styled.div`
display: flex;
`;

const Header = styled.h3`
margin-bottom: 10px;
font-size: 20px;
`;

const BasicList = styled.div`
display: grid;
grid-template-rows: repeat(auto-fit);
grid-template-columns: repeat(4, 1fr);
gap: 10px;
`;

const CraftBox = styled.div`
width: 100%;
margin-left: 20px;
`;

const CraftList = styled.div`
display: grid;
grid-template-columns: repeat(auto-fit, ${variable.itemSize}px);
gap: 10px;
`;

const ItemButton = styled.button<{ size: number }>`
width: ${({size}) => `${size}px`};
height: ${({size}) => `${size}px`};
`;
