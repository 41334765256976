import ChampionTooltip from "@components/modules/tft/ChampionTooltip";
import React, {useMemo, useState} from 'react';
import Clipboard from 'react-clipboard.js';
import {useDrop} from 'react-dnd';
import {TwitterIcon, TwitterShareButton} from "react-share";
import styled, {css} from "styled-components";

interface Props {
  champions: ITftChampion[];
  onSelect: (champion: ITftChampion | ITftChampion[]) => unknown
  onReset: () => unknown
  className?: string;
}

const TftTeam = ({champions, onSelect, className, onReset}: Props) => {
  const [copied, setCopied] = useState(false);
  const [, drop] = useDrop<{ type: 'Group', champions: ITftChampion[] }, unknown, unknown>({
    accept: 'Group',
    drop: (item) => onSelect(item.champions)
  });
  const empty = useMemo(() => champions.length > 0, [champions]);
  return <div className={className}>
    <Header>
      <Title>
        <h4>チーム構成</h4>
        <TeamCount>({champions.length})</TeamCount>
        <Button onClick={onReset}>リセット</Button>
        <Clip data-clipboard-text={location.href} onClick={() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 5000)
        }}>
          {copied ? 'コピーしました' : '共有リンク作成'}
        </Clip>
      </Title>
      <TwitterShareButton url={location.href} title="OP Teemo" hashtags={['TFT']}>
        <TweetButton><TwitterIcon size={20}/>Tweet</TweetButton>
      </TwitterShareButton>
    </Header>
    <ChampionBox ref={drop} empty={empty}>
      {empty
        ? champions.map(champion => <ChampionTooltip key={champion.id} size={77} champion={champion} onClick={onSelect}/>)
        : <Empty>チャンピオンアイコンをクリック又は、シナジーをドラッグ・アンド・ドロップで<br/>発動シナジーをシミュレートすることが出来ます。</Empty>}
    </ChampionBox>
  </div>;
};

export default TftTeam;

const Header = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 5px;
`;

const Title = styled.div`
display: flex;
align-items: center;
&>*+* {
margin-left: 5px;
}
`;

const TweetButton = styled.div`
box-sizing: border-box;
display: flex;
align-items: center;
padding: 0 6px 0 3px;
font-size: 10px;
color: #fff;
background-color: #00aced;
border-radius: 3px;
`;

const TeamCount = styled.div`
margin-right: 5px;
`;

const Button = styled.button`
padding: 5px;
font-size: 12px;
border: 1px solid #a88d74;
`;
const Clip = styled(Clipboard)`
padding: 5px;
font-size: 12px;
border: 1px solid #a88d74;
`;

const ChampionBox = styled.div<{ empty: boolean; }>`
padding: 15px;
border: 1px solid #a88d74;
${({empty}) => empty ? css`
display: grid;
grid-template-columns: repeat(auto-fit, 77px);
grid-template-rows: 77px;
grid-gap: 10px;
` : null}
`;

const Empty = styled.div`
box-sizing: border-box;
display: flex;
flex-direction: column;
justify-content: center;
height: 77px;
padding: 10px;
font-size: 14px;
text-align: center;
border: 1px dashed #a88d74;
`;

