import TftGroup from "@components/modules/tft/Group";

import classes from 'data/tft/classes.json';
import {sortBy} from 'lodash';
import React from 'react';
import styled from "styled-components";
import {iconImg} from "utils/image";

interface Props {
  champions: ITftChampion[];
  selects: ITftChampion[];
  onSelect: (champion: ITftChampion | ITftChampion[]) => unknown
}

const TftClasses = ({champions, selects, onSelect}: Props) => <div>
  <Title>クラス</Title>
  {sortBy(classes, [(o) => -(champions.filter(champion => champion.classes.includes(o.id))).length, 'id'])
    .map(_class =>
      <TftGroup
        key={_class.id}
        name={classes.filter(_c => _c.id === _class.id).pop()!.name}
        description={((): string | string[] => {
          const pop = classes.filter(_c => _c.id === _class.id).pop();
          return pop
            ? [...pop.descriptions, pop.individuality].filter(isNotNull)
            : ''
        })()}
        effects={classes.filter(_c => _c.id === _class.id).pop()!.effects}
        icon={iconImg(_class.key)}
        champions={champions.filter(c => c.classes
          .includes(_class.id))}
        selects={selects}
        onSelect={onSelect}
      />)}
</div>;

export default TftClasses;

const isNotNull = (data?: string): data is string => data !== undefined;

const Title = styled.h3`
margin-bottom: 5px;
font-size: 18px;
`;
