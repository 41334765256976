import ItemEffects from "@components/modules/tft/ItemEffects";
import ItemRecipeBox from "@components/modules/tft/ItemRecipeBox";
import React from 'react';
import styled from "styled-components";
import Item from './Item';

interface Props {
  items: ITftItem[];
}

const ItemTable = ({items}: Props) => <Table>
  <thead>
  <tr>
    <Icon>アイテム</Icon>
    <Recipe>素材</Recipe>
    <Th>効果</Th>
    <Effect>ステータス</Effect>
  </tr>
  </thead>
  <tbody>
  {items.map(item => <tr key={item.id}>
    <Td><ItemBox><Item item={item} size={60}/><ItemText>{item.name}</ItemText></ItemBox></Td>
    <Td><ItemRecipeBox item={item}/></Td>
    <Td>{item.description}</Td>
    <Td><ItemEffects effects={item.effects}/></Td>
  </tr>)}
  </tbody>
</Table>;
export default ItemTable;

const Table = styled.table`
width: 100%;
border-collapse: collapse;
border: 1px solid #a88d74;
`;

const Th = styled.th`
padding: 5px;
border: 1px solid #a88d74;
`;

const Icon = styled(Th)`
width: 180px;
`;

const Recipe = styled(Th)`
width: 100px;
`;

const Effect = styled(Th)`
width: 100px;
`;

const Td = styled.td`
padding: 5px;
border: 1px solid #a88d74;
`;

const ItemBox = styled.div`
display: flex;
flex-direction: column;
align-items: center;
padding: 5px;
`;

const ItemText = styled.div`
font-size: 12px;
`;

