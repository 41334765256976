import Footer from "@components/modules/common/Footer";
import Header from "@components/modules/common/Header";
import React, {FC} from 'react';
import styled from "styled-components";

const PageLayout: FC = ({children}) =>
  <Box>
    <Header/>
    {children}
    <Footer/>
  </Box>;
export default PageLayout;

const Box = styled.div`
display: grid;
grid-template-rows: min-content min-content min-content;
grid-row-gap: 10px;
`;
