import Synergies, {Type} from "@components/modules/tft/Synergies";
import React from 'react';

interface Props {
  classes: ITftSynergy[];
  selects: ITftChampion[];
}

const ActiveClasses = ({classes, selects}: Props) =>
  <Synergies title="発動クラス" type={Type.Class} synergies={classes} selects={selects} emptyContent="発動クラスはありません"/>;

export default ActiveClasses;
