import Items from "data/tft/items.json";
import {isEqual} from 'lodash';
import React from "react";
import styled from "styled-components";
import Item from "./Item";

interface Props {
  className?: string;
}

const TftItems = ({className}: Props) => {
  const basicItems = Items.filter(item => item.recipes.length === 0);
  return (<Table className={className}>
    <Title>アイテム</Title>
    <thead>
    <tr>
      <th/>
      {basicItems.map(
        item => <TTh key={item.id}><ItemCell item={item}/></TTh>)}
    </tr>
    </thead>
    <tbody>
    {basicItems.map(
      base => <tr key={base.id}>
        <Th><ItemCell item={base}/></Th>
        {basicItems.map(next => {
          const recipe = [base.id, next.id];
          const _i = Items.filter(
            _item => isEqual(recipe.sort(), _item.recipes.sort())).pop()!;
          return <Td key={_i.id}><ItemCell item={_i}/></Td>;
        })}
      </tr>)}
    </tbody>
  </Table>);
};
export default TftItems;

const Table = styled.table`
border-collapse: collapse;
`;

const Title = styled.caption`
margin-bottom: 5px;
font-size: 18px;
font-weight: bold;
text-align: center;
`;

const TTh = styled.th`
border-right: 1px solid #a88d74;
border-left: 1px solid #a88d74;
`;

const Th = styled.th`
width: 90px;
border-top: 1px solid #a88d74;
border-bottom: 1px solid #a88d74;
`;

const Td = styled.td`
box-sizing: border-box;
width: 90px;
font-size: 10px;
text-align: center;
border: 1px solid #a88d74;
`;

const ItemCell = styled(Item)`
display: flex;
align-items: center;
justify-content: center;
`;
