import React, {useEffect} from "react";
import ReactGA from 'react-ga';
import {RouteComponentProps, withRouter} from "react-router";

const LocationWatcher: React.FC<RouteComponentProps> = ({children, location}) => {
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location]);
  return (<>{children}</>);
};

export default withRouter(LocationWatcher);
