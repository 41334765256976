// import PageLayout from "@components/layouts/PageLayout";
import StretchLayout from "@components/layouts/StretchLayout";
import {ItemFilter, ItemTable} from "@components/modules/tft";
import React, {useState} from "react";
import styled from "styled-components";
import itemData from '../../../data/tft/items.json';

const TftItemTemplate = () => {
  const [filters, setFilters] = useState<Array<IFilter<ITftItem>>>(
    itemData.map(item => ({id: item.id, text: item.name, active: false, value: item})));
  return <StretchLayout>
    <Header>アイテム一覧</Header>
    <Box>
      <ItemFilter filters={filters} onClick={(id: string) => setFilters(filters
        .map(filter => filter.id === id ? ({...filter, active: !filter.active}) : filter)
        .map(filter =>
          filter.value.recipes.includes(id) ? ({
            ...filter,
            active: !filters.find(_f => _f.id === id)!.active
          }) : filter))}/>
      <ItemTable items={filters.filter(filter => filter.active || filters.every(all => !all.active)).map(
        filter => filter.value)}/>
    </Box>
  </StretchLayout>;
};
export default TftItemTemplate;

const Header = styled.h2`
display: none;
`;

const Box = styled.div`
display: grid;
grid-template-rows: auto auto;
grid-row-gap: 10px;
padding: 20px 10px;
`;
