import Item from "@components/modules/tft/Item";
import itemData from "@data/tft/items.json";
import React, {FC} from 'react';
import styled from "styled-components";

interface Props {
  item: ITftItem;
  className?: string;
}

const ItemRecipeBox: FC<Props> = ({item, className}) =>
  <RecipeBox className={className}>{item.recipes.length > 0 ? <>
    <Item item={itemData.find((base) => base.id === item.recipes[0])!}/><RecipePlus> + </RecipePlus>
    <Item item={itemData.find((base) => base.id === item.recipes[1])!}/></> : 'なし'}</RecipeBox>;
export default ItemRecipeBox;


const RecipeBox = styled.div`
display: flex;
align-items: center;
justify-content: center;
`;

const RecipePlus = styled.span`
padding: 0 5px;
`;
