import ChampionTooltip from "@components/modules/tft/ChampionTooltip";
import React from 'react';
import styled from "styled-components";

interface Props {
  cost: number;
  champions: ITftChampion[];
  selects: ITftChampion[];
  onClick: (champion: ITftChampion) => unknown;
}

const TftTier = ({cost, champions, selects, onClick}: Props) => (<Box>
  <Title>ティア{cost}</Title>
  <List>
    {champions.map(
      champion => <ChampionTooltip
        selected={selects.includes(champion)}
        key={champion.id}
        size={45}
        champion={champion}
        tooltip={false}
        onClick={onClick}
      />)}
  </List>
</Box>);
export default TftTier;

const Box = styled.div`
margin-bottom: 5px;
`;

const Title = styled.h4`
margin-bottom: 5px;
font-size: 16px;
`;

const List = styled.div`
display: grid;
grid-template-columns: repeat(auto-fit, 45px);
grid-gap: 3px;
`;
