import StretchLayout from "@components/layouts/StretchLayout";
import React from 'react';
import {Link} from 'react-router-dom';

const NotFoundTemplate = () => (
  <StretchLayout>
    <div>
      <h1>このページは存在しません。</h1>
      <Link to="/">ホームへ戻る</Link>
    </div>
  </StretchLayout>);

export default NotFoundTemplate;
