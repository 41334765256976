import championData from '@data/tft/champions.json';
import classData from '@data/tft/classes.json';
import originData from '@data/tft/origins.json';
import React, {FC} from 'react';
import styled from "styled-components";
import {championImg, iconImg} from "utils/image";

const TftChampionDetail: FC<ITftChampion> = ({
                                         id, name, cost, health, mana, startMana, armor, magicResit, damage, attackSpeed, criticalChance, range, skill, origins, classes
                                       }) => {
  return (<div>
    <TooltipTitle>
      {name}
    </TooltipTitle>
    <TooltipSubTitle>ステータス</TooltipSubTitle>
    <TooltipStatus>
      <TooltipContent>
        <TooltipText>Cost: {cost}</TooltipText>
        <TooltipText>HP: {[1, 1.8, 3.24].map(c => (health * c).toFixed()).join('/')}</TooltipText>
        <TooltipText>MP: {mana}</TooltipText>
        <TooltipText>AR: {armor}</TooltipText>
        <TooltipText>MR: {magicResit}</TooltipText>
        <TooltipText>AD: {[1, 1.8, 3.24].map(c => (damage * c).toFixed()).join('/')}</TooltipText>
        <TooltipText>AS: {attackSpeed}</TooltipText>
        <TooltipText>CT: {criticalChance}%</TooltipText>
        <TooltipText>RG: {range}</TooltipText>
      </TooltipContent>
      <div>
        <TooltipSynergyBox>
          {origins.map(origin => <TooltipSynergy key={origin}>
            <Icon src={iconImg(originData.find(c => c.id === origin)!.key)}/>
            {originData.find(c => c.id === origin)!.name}
          </TooltipSynergy>)}
          {classes.map(_class => <TooltipSynergy key={_class}>
            <Icon src={iconImg(classData.find(c => c.id === _class)!.key)}/>
            {classData.find(c => c.id === _class)!.name}
          </TooltipSynergy>)}
        </TooltipSynergyBox>
      </div>
    </TooltipStatus>
    <TooltipSubTitle>スキル({skill.name})
      <div><img src="/assets/tft/icons/mana.png" alt="mana" height={12}/> {startMana ? startMana : 0}/{mana}</div>
    </TooltipSubTitle>
    <TooltipContent>
      {skill.description.split("\n").map((text, index) =>
        <TooltipText key={`skill-${index}`}>{text}</TooltipText>)}
    </TooltipContent>
    <div>
      <TooltipSubTitle>シナジー</TooltipSubTitle>
      <TooltipSynergyChampionBox>
        {origins.map(
          origin => <TooltipSynergyChampion key={origin}><Icon src={iconImg(originData.find(c => c.id === origin)!.key)} size={40}/>{
            championData.filter(c => c.origins.includes(origin) && c.id !== id).length > 0 ?
              championData.filter(c => c.origins.includes(origin) && c.id !== id)
                .map(c => <Img key={c.id} size={40} tier={c.cost} src={championImg(c.id)}/>)
              : 'なし'
          }</TooltipSynergyChampion>)}
      </TooltipSynergyChampionBox>
      <TooltipSynergyChampionBox>
        {classes.map(
          _class => <TooltipSynergyChampion key={_class}><Icon src={iconImg(classData.find(c => c.id === _class)!.key)} size={40}/>{
            championData.filter(c => c.classes.includes(_class) && c.id !== id).length > 0
              ? championData.filter(c => c.classes.includes(_class) && c.id !== id)
                .map(c => <Img key={c.id} size={40} tier={c.cost} src={championImg(c.id)}/>)
              : 'なし'
          }</TooltipSynergyChampion>)}
      </TooltipSynergyChampionBox>
    </div>
  </div>);
};
export default TftChampionDetail;

const TooltipTitle = styled.h4`
margin-bottom: 5px;
`;

const TooltipContent = styled.div`
padding: 5px;
`;

const TooltipStatus = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
column-gap: 10px;
`;

const TooltipSubTitle = styled.h5`
display: flex;
align-items: center;
justify-content: space-between;
font-size: 13px;
`;

const TooltipSynergyBox = styled.div`
margin-bottom: 5px;
`;

const TooltipSynergy = styled.div`
display: flex;
align-items: center;
`;

const TooltipSynergyChampionBox = styled.div`
&+& {
margin-top: 5px;
}
`;

const TooltipSynergyChampion = styled.div`
display: flex;
align-items: center;
&>*+* {
margin-left: 5px;
}
`;

const TooltipText = styled.p`
&+& {
margin-top: 1px;
}
`;

const Img = styled.img<{ tier: number; size: number; selected?: boolean }>`
width: ${props => props.size}px;
height: ${props => props.size}px;
border: 1px solid ${props => props.selected ? 'gray' : tierColor(props.tier)};
`;

const Icon = styled.div<{src: string, size?: number}>`
width: ${({size = 30}) => size - 10}px;
height: ${({size = 30}) => size -10}px;
margin: 2.5px;
background-color: #a88d74;
mask: url("${props => props.src}") no-repeat center;
mask-size: contain;
`;

const tierColor = (cost: number) => {
  switch (cost) {
    case 2:
      return 'green';
    case 3:
      return 'blue';
    case 4:
      return 'purple';
    case 5:
      return 'yellow';
    default:
      return 'gray'
  }
};
