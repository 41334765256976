import TftGroup from "@components/modules/tft/Group";
import origins from 'data/tft/origins.json';
import {sortBy} from 'lodash';
import React from 'react';
import styled from "styled-components";
import {iconImg} from "utils/image";

interface Props {
  champions: ITftChampion[];
  selects: ITftChampion[];
  onSelect: (champion: ITftChampion | ITftChampion[]) => unknown
}

const TftOrigin = ({champions, selects, onSelect}: Props) => <div>
  <Title>オリジン</Title>
  {sortBy(origins, [(o) => -(champions.filter(champion => champion.origins.includes(o.id))).length, 'id'])
    .map(origin =>
      <TftGroup
        key={origin.id}
        name={origins.filter(_origin => _origin.id === origin.id).pop()!.name}
        description={((): string | string[] => {
          const pop = origins.filter(_c => _c.id === origin.id).pop();
          return pop
            ? pop.descriptions
            : ''
        })()}
        effects={origins.filter(_origin => _origin.id === origin.id).pop()!.effects}
        icon={iconImg(origin.key)}
        champions={champions.filter(c => c.origins.includes(origin.id))}
        selects={selects}
        onSelect={onSelect}
      />)}
</div>;

export default TftOrigin;

const Title = styled.h3`
margin-bottom: 5px;
font-size: 18px;
`;
