import React, {FC} from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {championImg} from "../../../utils/image";

export interface ChampionManProps {
  href: string;
  champion: ITftChampion;
  size: number;
  className?: string;
}

export const ChampionMan: FC<ChampionManProps> = ({href, size, champion, className}) =>
  <Link to={href} className={className}>
    <Img size={size} tier={champion.cost} src={championImg(champion.id)} alt={champion.name}/>
  </Link>;

const Img = styled.img<{ tier: number; size: number; selected?: boolean }>`
width: ${props => props.size}px;
height: ${props => props.size}px;
border: 1px solid ${props => props.selected ? 'gray' : tierColor(props.tier)};
`;

const tierColor = (cost: number) => {
  switch (cost) {
    case 2:
      return 'green';
    case 3:
      return 'blue';
    case 4:
      return 'purple';
    case 5:
      return 'yellow';
    default:
      return 'gray';
  }
};
