import {ChampionMan} from "@components/modules/tft/ChampionMan";
import React, {FC} from 'react';
import styled from "styled-components";

interface Props {
  champions: ITftChampion[];
}

const ChampionBox: FC<Props> = ({champions}: Props) =>
  <div>
    <h2>チャンピオン情報</h2>
    <List>
      {champions.map(champion => <ChampionMan key={champion.id} size={74} champion={champion}
                                              href={`/tft/champions/${champion.id}`}/>)}
    </List>
  </div>;
export default ChampionBox;

const List = styled.div`
display: grid;
grid-template-columns: repeat(auto-fit, 74px);
grid-gap: 10px;
padding: 10px;
margin-top: 5px;
border: 1px solid #a88d74;
`;
