import Synergies, {Type} from "@components/modules/tft/Synergies";
import React from 'react';

interface Props {
  origins: ITftSynergy[];
  selects: ITftChampion[];
}

const ActiveOrigins = ({origins, selects}: Props) =>
  <Synergies title="発動オリジン" type={Type.Origin} synergies={origins} selects={selects} emptyContent="発動オリジンはありません"/>;

export default ActiveOrigins;
