import NotFoundPage from "@components/pages/NotFoundPage";
import React from "react";
import {Redirect} from "react-router";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import TftRoutes from "routes/TftRoutes";
import LocationWatcher from "watchers/LocationWatcher";

export default function Routes() {
  return <BrowserRouter>
    <LocationWatcher/>
    <Switch>
      <Redirect exact path="/" to="/tft"/>
      <Route path="/tft" component={TftRoutes}/>
      <Route path="/*" component={NotFoundPage}/>
    </Switch>
  </BrowserRouter>;
};
