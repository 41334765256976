import TftChampionPage from "@components/pages/tft/TftChampionPage";
import TftItemPage from "@components/pages/tft/TftItemPage";
import TftSimulatorPage from "@components/pages/tft/TftSimulatorPage";
import TftSummonerDetailPage from "@components/pages/tft/TftSummonerDetailPage";
import TftSummonersPage from "@components/pages/tft/TftSummonersPage";
import TftChampionDetailPage from "@pages/tft/TftChampionDetailPage";
import React from "react";
import {Redirect, Route, RouteComponentProps, Switch} from "react-router";

type Props = RouteComponentProps;

export default ({match}: Props) => (<Switch>
  <Route exact path={`${match.url}/simulator`} component={TftSimulatorPage}/>
  <Route path={`${match.url}/simulator/:championCodes`} component={TftSimulatorPage}/>
  <Route path={`${match.url}/items`} component={TftItemPage}/>
  <Route exact path={`${match.url}/champions`} component={TftChampionPage}/>
  <Route path={`${match.url}/champions/:id`} component={TftChampionDetailPage}/>
  <Route exact path={`${match.url}/summoners`} component={TftSummonersPage}/>
  <Route path={`${match.url}/summoners/:name`} component={TftSummonerDetailPage}/>
  <Redirect path={`${match.url}/:championCodes`} to="/tft/simulator/:championCodes"/>
  <Redirect path={`${match.url}`} to="/tft/simulator"/>
</Switch>);
