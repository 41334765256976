import React, {FC, SyntheticEvent} from 'react';
import styled from "styled-components";

interface Props {
  id: string;
  text: string;
  active: boolean;
  onClick: (id: string) => unknown;
}

const ChampionFilterItem: FC<Props> = ({id, text, active, onClick}: Props) => {
  const handleOnClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onClick(id);
  };
  return <Li>
    <Item onClick={handleOnClick}>
      <p>{text}</p>
      <Radio active={active}/>
    </Item>
  </Li>;
};
export default ChampionFilterItem;

const Li = styled.li`
margin-left: 30px;
`;

const Item = styled.a`
display: flex;
justify-content: space-between;
width: 100%;
`;

const Radio = styled.div<{ active?: boolean }>`
width: 15px;
height: 15px;
background-color: ${({active}) => active ? '#a88d74' : 'none'};
border: 1px solid #a88d74;
border-radius: 8px;
`;
