import TftSynergy from "@components/modules/tft/Synergy";
import {sortBy} from 'lodash';
import {TftCondition} from 'models/tft/TftCondition';
import React, {useCallback, useMemo, useState} from 'react';
import styled, {css} from "styled-components";

export enum Type {
  Origin, Class
}

interface Props {
  title: string;
  type: Type
  synergies: ITftSynergy[];
  selects: ITftChampion[];
  emptyContent: string;
}

const Synergies = ({title, type, synergies, selects, emptyContent}: Props) => {
  const [open, setOpen] = useState(true);

  const convertActives = useCallback((synergy: ITftSynergy) => sortBy(synergy.effects, 'count').reverse()
    .map(effect => {
      if (effect.condition === TftCondition.Only) {
        return effect.count === selects.filter(champion => Type.Class === type ? champion.classes.includes(synergy.id)
          : champion.origins.includes(synergy.id)).length;
      }
      return effect.count <= selects.filter(champion => Type.Class === type ? champion.classes.includes(synergy.id)
        : champion.origins.includes(synergy.id)).length;
    }), [type, selects]);

  const empty = useMemo<boolean>(() => !open && synergies.filter(synagy => open
    ? true
    : convertActives(synagy).includes(true)).length === 0,
    [synergies, open, convertActives]);
  return <Box>
    <Toggle onClick={() => setOpen(!open)}>{open ? '-' : '+'}</Toggle>
    <Title>{title}</Title>
    <List empty={empty}>
      {empty ? <div>{emptyContent}</div>
        : synergies.filter(synergy => open ? true : convertActives(synergy).includes(true))
          .map(synergy => <TftSynergy
            key={synergy.id}
            _key={synergy.key}
            {...synergy}
            count={selects.filter(champion => Type.Class === type ? champion.classes.includes(synergy.id)
              : champion.origins.includes(synergy.id)).length}
            actives={convertActives(synergy)}/>)}
    </List>
  </Box>;
};

export default Synergies;

const Box = styled.div`
position: relative;
`;

const Title = styled.h4`
margin-bottom: 5px;
text-align: center;
`;

const Toggle = styled.button`
position: absolute;
padding: 0 3px;
font-size: 12px;
border: 1px solid #a88d74;
border-radius: 3px;
`;

const List = styled.div<{ empty: boolean }>`
${({empty}) => empty ? css`
display: flex;
border: 1px solid #a88d74;
height: 59px;
justify-content: center;
align-items: center;
font-size: 16px;
` : css`
display: grid;
grid-template-columns: repeat(auto-fit, 75px);
grid-column-gap: 5px;
grid-row-gap: 5px;
`};
`;
