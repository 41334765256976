import PageLayout from "@components/layouts/PageLayout";
import {Rank} from "@models/Rank";
import {Tier} from "@models/Tier";
import React, {FC} from 'react';
import styled from "styled-components";

interface Props {
  name: string;
  wins: number;
  looses: number;
  rank: Rank;
  tier: Tier;
}

const TftSummonerDetailTemplate: FC<Props> = ({name}) => <PageLayout>
  <Header>
    <img src="" alt=""/>
    {name}
  </Header>
</PageLayout>;

export default TftSummonerDetailTemplate;

const Header = styled.div`
display: flex;
`;
