import Footer from "@components/modules/common/Footer";
import Header from '@components/modules/common/Header';
import React, {FC} from 'react';
import styled from "styled-components";

const StretchLayout: FC = ({children}) =>
  <Box>
    <Header/>
    {children}
    <Footer/>
  </Box>;
export default StretchLayout;

const Box = styled.div`
display: grid;
grid-template-rows: min-content 1fr min-content;
grid-row-gap: 10px;
align-items: flex-start;
height: 100vh;
`;
