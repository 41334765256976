import TftTier from "@components/modules/tft/Tier";
import {sortedUniq} from 'lodash';
import React from 'react';

interface Props {
  champions: ITftChampion[];
  selects: ITftChampion[];
  onClick: (champion: ITftChampion) => unknown;
}

const TftTiers = ({champions, selects, onClick}: Props) => {
  return (
    <div>
      {sortedUniq(
        champions
          .map(champion => champion.cost)
          .sort()).map(
        cost => <TftTier
          selects={selects}
          key={cost}
          cost={cost}
          champions={champions.filter(champion => champion.cost === cost)}
          onClick={onClick}
        />)}
    </div>
  );
};
export default TftTiers;
