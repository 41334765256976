import Tooltip from "@components/modules/common/Tooltip";
import ChampionTooltip from "@components/modules/tft/ChampionTooltip";
import {sortBy} from 'lodash';
import React from 'react';
import {useDrag} from "react-dnd";
import styled from "styled-components";

interface Props {
  name: string;
  icon: string;
  description?: string | string[];
  effects: ITftEffect[];
  champions: ITftChampion[];
  selects: ITftChampion[];
  onSelect: (champion: ITftChampion | ITftChampion[]) => unknown
}

const TftGroup = ({name, icon, description, effects, champions, selects, onSelect}: Props) => {
  const [, drag] =
    useDrag({item: {type: 'Group', champions: sortBy(champions, 'cost')}});
  return (
    <>
      <Tooltip id={name} effect="solid" border>
        <TooltipHeader><TooltipImg src={icon}/><TooltipTitle>{name}</TooltipTitle></TooltipHeader>
        <TooltipBody>
          {Array.isArray(description) ? description.map((d) => <p key={d}>{d}</p>) : description}
          {effects.map(effect => <p key={effect.text}>{effect.count}: {effect.text}</p>)}
        </TooltipBody>
      </Tooltip>
      <Box ref={drag} data-tip data-for={name}>
        <Header><Title><Img src={icon}/>{name}</Title>
          <button onClick={() =>
            onSelect(sortBy(champions, 'cost'))}><ReverseImg size={15} src="/assets/yajirusi.png" alt=""/>
          </button>
        </Header>
        <Champions>
          {sortBy(champions, 'cost')
            .map(champion => <ChampionTooltip key={champion.id} selected={selects.includes(champion)}
                                          champion={champion} size={45} tooltip={false} onClick={onSelect}/>)}
        </Champions>
      </Box>
    </>);
};

export default TftGroup;

const Box = styled.div`
&+& {
  margin-top: 5px;
}
`;

const TooltipImg = styled.div<{src: string}>`
width: 20px;
height: 20px;
background-color: #a88d74;
mask: url("${props => props.src}") no-repeat center;
mask-size: contain;
`;

const TooltipTitle = styled.p`
padding: 5px;
`;

const TooltipHeader = styled.div`
display: flex;
`;

const TooltipBody = styled.div`
padding: 0 5px;
line-height: 1.5;
`;

const Header = styled.h4`
display: flex;
margin-bottom: 5px;
font-size: 16px;
`;

const Title = styled.div`
display: flex;
padding: 5px 0 3px;
`;

const ReverseImg = styled.img<{ size: number }>`
width: ${({size}) => size}px;
height: ${({size}) => size}px;
margin-left: 5px;
`;

const Champions = styled.div`
display: grid;
grid-template-columns: repeat(auto-fit, 45px);
grid-gap: 3px;
padding-left: 10px;
`;

const Img = styled.div<{src: string}>`
width: 1em;
height: 1em;
margin-right: .25em;
background-color: #a88d74;
mask: url("${props => props.src}") no-repeat center;
mask-size: contain;
  `
