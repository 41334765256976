import champions from "data/tft/champions.json";
import {compact} from "lodash";

export const generatePath = (champions: ITftChampion[]): string =>
  champions.map(champion => champion.code.toString(16))
    .join('-');

export const parsePath = (path?: string): ITftChampion[] =>
  path ? compact(path.split('-')
    .map(code => parseInt(code, 16))
    .map(code => champions.filter(champion => champion.code === code).pop())) : [];

