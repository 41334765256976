import StretchLayout from "@components/layouts/StretchLayout";
import {ChampionFilter} from "@components/modules/tft";
import ChampionBox from "@components/modules/tft/ChampionBox";
import {FilterType} from "@components/modules/tft/ChampionFilter";
import {uniq} from "lodash";
import React, {useCallback, useMemo, useState} from "react";
import styled from "styled-components";
import championData from "../../../data/tft/champions.json";
import classData from "../../../data/tft/classes.json";
import originData from "../../../data/tft/origins.json";

const initTier = uniq(championData.map(champion => champion.cost))
  .sort().map(tier => ({id: `tier-${tier}`, text: `ティアー${tier}`, active: true}));
const initOrigin = originData.map(origin => ({id: origin.id, text: origin.name, active: true}));
const initClass = classData.map(_class => ({id: _class.id, text: _class.name, active: true}));
const TftChampionTemplate = () => {
  const [tiers, setTiers] = useState<IFilter[]>(initTier);
  const [origins, setOrigins] = useState<IFilter[]>(initOrigin);
  const [classes, setClasses] = useState<IFilter[]>(initClass);
  const handleOnClick = useCallback((type: FilterType, id: string) => {
    if (type === FilterType.Tier) {
      setTiers(tiers.map(tier => tier.id === id ? {...tier, active: !tier.active} : tier))
    } else if (type === FilterType.Origin) {
      setOrigins(origins.map(origin => origin.id === id ? {...origin, active: !origin.active} : origin))
    } else if (type === FilterType.Class) {
      setClasses(classes.map(_class => _class.id === id ? {..._class, active: !_class.active} : _class))
    }
  }, [tiers, origins, classes]);
  const handleOnReset = useCallback(() => {
    setTiers(initTier);
    setOrigins(initOrigin);
    setClasses(initClass);
  }, []);
  const champions = useMemo(() =>
    championData.filter(champion =>
      tiers
        .filter(tier => tier.active)
        .map(tier => parseInt(tier.id.split('-')[1]))
        .includes(champion.cost)
      &&
      champion.origins.some(origin => origins
        .filter(_origin => _origin.active)
        .map(_origin => _origin.id)
        .includes(origin))
      &&
      champion.classes.some(_class => classes
        .filter(__class => __class.active)
        .map(__class => __class.id)
        .includes(_class))), [tiers, origins, classes]);
  return <StretchLayout>
    <Box>
      <ChampionFilter tiers={tiers} origins={origins} classes={classes} onClick={handleOnClick}
                      onReset={handleOnReset}/>
      <ChampionBox champions={champions}/>
    </Box>
  </StretchLayout>;
};
export default TftChampionTemplate;

const Box = styled.div`
display: grid;
grid-template-columns: 200px auto;
align-items: flex-start;
padding: 20px 10px;
`;
