import ChampionFilterItem from "@components/modules/tft/ChampionFilter/ChampionFilterItem";
import React, {useState} from 'react';
import styled from 'styled-components';

interface Props {
  text: string;
  filters: IFilter[];
  onClick: (id: string) => unknown;
}

const ChampionFilterFilter = ({text, filters, onClick}: Props) => {
  const [open, setOpen] = useState(false);
  return (
    <button onClick={() => setOpen(!open)}>
      <Title><Arrow active={open}>▲</Arrow>{text}</Title>
      {open ? <List>
        {filters.map((filter) => <ChampionFilterItem key={filter.id} {...filter} onClick={onClick}/>)}
      </List> : null}
    </button>
  );
};

export default ChampionFilterFilter;

const Title = styled.div`
display: flex;
padding: 5px 0;
text-align: left;
`;

const Arrow = styled.div<{ active?: boolean }>`
transition: transform 200ms 0s ease;
transform: rotate(${({active}) => active ? '180deg' : '90deg'});
`;

const List = styled.ul`
&>li+li {
margin-top: 5px;
}
`;
