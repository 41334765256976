import React, {FC} from 'react';
import {Link} from "react-router-dom";
import styled from "styled-components";

const Header: FC = () =>
  <Box>
    <Link to="/"><h1><img src="/assets/logo.png" alt="logo" height={50}/></h1></Link>
    <Tabs>
      <Tab><SLink to="/tft/simulator">シミュレーター</SLink></Tab>
      <Tab><SLink to="/tft/champions">チャンピオン</SLink></Tab>
      <Tab><SLink to="/tft/items">アイテム</SLink></Tab>
    </Tabs>
    <Profile>Patch 10.23</Profile>
  </Box>;

export default Header;

const Box = styled.header`
display: flex;
justify-content: space-between;
padding: 4px 5px 4px;
border-bottom: 2px solid #A88D74;
`;

const Tabs = styled.div`
display: flex;
align-items: center;
`;

const Tab = styled.div`
font-size: 20px;
&+& {
margin-left: 40px;
}
`;

const SLink = styled(Link)`
color: #a88d74;
text-decoration: none;
`;

const Profile = styled.div`
display: flex;
align-items: center;
justify-content: flex-end;
width: 250px;
padding-right: 20px;
font-size: 17px;
color: rgba(168,141,116,0.7);
`;
