import drops from 'data/tft/drops.json';
import {sortBy} from "lodash";
import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

const LevelMatrix = () => (<Box>
  <Title>チャンピオン総数＆出現率</Title>
  <Table cellSpacing={0}>
    <thead>
    <tr>
      <Th/><Th>枚数</Th><Th>レベル1</Th><Th>レベル2</Th><Th>レベル3</Th><Th>レベル4</Th><Th>レベル5</Th><Th>レベル6</Th><Th>レベル7</Th><Th>レベル8</Th><Th>レベル9</Th>
    </tr>
    </thead>
    <tbody>
    <tr><Th>ティア1</Th><Th>{drops.totals.filter(total => total.tier === 1).pop()!.total}</Th>{sortBy(
      drops.drops.filter(drop => drop.tier === 1), 'level').map(
      drop => <Td key={`drop-${drop.tier}-${drop.level}`}>{drop.drop}%</Td>)}</tr>
    <tr><Th>ティア2</Th><Th>{drops.totals.filter(total => total.tier === 2).pop()!.total}</Th>{sortBy(
      drops.drops.filter(drop => drop.tier === 2), 'level').map(
      drop => <Td key={`drop-${drop.tier}-${drop.level}`}>{drop.drop}%</Td>)}</tr>
    <tr><Th>ティア3</Th><Th>{drops.totals.filter(total => total.tier === 3).pop()!.total}</Th>{sortBy(
      drops.drops.filter(drop => drop.tier === 3), 'level').map(
      drop => <Td key={`drop-${drop.tier}-${drop.level}`}>{drop.drop}%</Td>)}</tr>
    <tr><Th>ティア4</Th><Th>{drops.totals.filter(total => total.tier === 4).pop()!.total}</Th>{sortBy(
      drops.drops.filter(drop => drop.tier === 4), 'level').map(
      drop => <Td key={`drop-${drop.tier}-${drop.level}`}>{drop.drop}%</Td>)}</tr>
    <tr><Th>ティア5</Th><Th>{drops.totals.filter(total => total.tier === 5).pop()!.total}</Th>{sortBy(
      drops.drops.filter(drop => drop.tier === 5), 'level').map(
      drop => <Td key={`drop-${drop.tier}-${drop.level}`}>{drop.drop}%</Td>)}</tr>
    </tbody>
  </Table>
</Box>);
export default LevelMatrix;
const Box = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
`;

const Title = styled.h4`
margin-bottom: 5px;
text-align: center;
`;

const Table = styled.table`
font-size: 22px;
border-collapse: collapse;
${media.lessThan("medium")`
  font-size: 16px;
`}
`;

const Th = styled.th`
padding: 5px 7px;
font-size: 16px;
${media.lessThan("medium")`
  font-size: 10px;
  padding: 5px 0;
`}
`;

const Td = styled.td`
padding: 5px 18px;
text-align: center;
border: 1px solid #a88d74;
${media.lessThan("medium")`
  padding: 5px 3px;
`}
`;
