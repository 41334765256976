import {TftChampionDetailTemplate} from "@components/templates/tft/TftChampionDetailTemplate";
import React, {FC} from 'react';
import {RouteComponentProps, withRouter} from "react-router";

type Props = RouteComponentProps<{ id: string }>;

const TftChampionDetailPage: FC<Props> = ({match: {params: {id}}}: Props) =>
  <TftChampionDetailTemplate id={id}/>;

export default withRouter<Props, FC<Props>>(TftChampionDetailPage);
