import ChampionTooltip from "@components/modules/tft/ChampionTooltip";
import champions from 'data/tft/champions.json';
import React from 'react';
import styled, {css} from "styled-components";
import {iconImg} from "utils/image";
import Tooltip from '../common/Tooltip';

interface Props extends ITftSynergy {
  _key: string;
  count: number;
  actives: boolean[];
}

const TftSynergy = ({id, _key, name, individuality, descriptions, effects, actives, count}: Props) => (<Box>
  <BImg data-tip data-for={id}>
    <Tooltip id={id} effect="solid" border>
      <TooltipHeader><TooltipImg src={iconImg(_key)}/><TooltipTitle>{name}</TooltipTitle></TooltipHeader>
      <TooltipBody>
        <TooltipDetail>
          <div>{individuality}</div>
          <div>{descriptions.map(d => <p>{d}</p>)}</div>
        </TooltipDetail>
        <div>{effects.map(effect => <p key={effect.text}>{effect.count}: {effect.text}</p>)}</div>
        <TooltipChampions>{champions.filter(champion => champion.origins.includes(id) || champion.classes.includes(id))
          .map(champion => <ChampionTooltip key={champion.id} size={40} champion={champion}
                                            onClick={() => {}}/>)}</TooltipChampions>
      </TooltipBody>
    </Tooltip>
    <Count active={count > 0}>{count}</Count>
    <Img active={actives.includes(true)} src={iconImg(_key)}/>
  </BImg>
  <Cells>
    {actives
      .map((active, index) => <Cell key={index}><Indicator active={active}> </Indicator></Cell>)}
  </Cells>
</Box>);

export default TftSynergy;

const TooltipImg = styled.div<{src: string}>`
width: 20px;
height: 20px;
background-color: #a88d74;
mask: url("${props => props.src}") no-repeat center;
mask-size: contain;
`;

const TooltipTitle = styled.p`
padding: 5px;
`;

const TooltipHeader = styled.div`
display: flex;
`;

const TooltipBody = styled.div`
padding: 0 5px;
line-height: 1.5;
`;

const TooltipDetail = styled.div`
padding-bottom: 5px;
`;

const TooltipChampions = styled.div`
margin-top: 5px;

& > img + img {
  margin-left: 5px;
}
`;

const Box = styled.div`
display: grid;
grid-template-columns: 60px 10px;
grid-column-gap: 5px;
`;

const Count = styled.div<{ active: boolean }>`
position: absolute;
right: 0;
bottom: 0;
width: 15px;
height: 15px;
font-size: 10px;
font-weight: bold;
line-height: 14px;
color: rgba(168, 141, 116, ${props => props.active ? 1 : 0.3});
text-align: center;
border-top: 1px solid #a88d74;
border-left: 1px solid #a88d74;
`;

const Cells = styled.div`
display: grid;
grid-template-rows: repeat(auto-fit, 1fr);
grid-template-columns: 10px;
grid-row-gap: 2px;
`;

const Cell = styled.div`
position: relative;
border: 1px solid #a88d74;
`;

const Indicator = styled.div<{ active: boolean; }>`
position: absolute;
top: ${props => props.active ? '0' : '100%'};
right: 0;
bottom: 0;
left: 0;
background-color: #a88d74;
transition: top 300ms 0s ease;
`;

const BImg = styled.div`
position: relative;
box-sizing: border-box;
width: 59px;
height: 59px;
padding: 9px;
border: 1px solid #a88d74;
`;

const Img = styled.div<{ active: boolean, src: string }>`
width: 35px;
height: 35px;
background-color: #a88d74;
opacity: 0.1;
mask: url("${props => props.src}") no-repeat center;
mask-size: contain;
transition: opacity 300ms 0s ease;

${props => props.active && css`
  opacity: 1;
`}
`;

