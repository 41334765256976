import React from 'react';
import styled from 'styled-components';

const Footer = () => (<Box>
  <SiteLinks>
    <a href="mailto:op.teemo.develop@gmail.com">お問い合わせ</a>
  </SiteLinks>
  <License>
    <p>© {new Date().getFullYear()} OP Teemo.</p>
    <p>OP Teemo isn't endorsed by Riot Games and doesn't reflect the views or opinions of Riot Games or anyone
      officially involved in producing or managing League of Legends.</p>
    <p>League of Legends and Riot Games are trademarks or registered trademarks of Riot Games, Inc. League of Legends ©
      Riot Games, Inc.</p>
  </License>
</Box>);
export default Footer;

const Box = styled.footer`
background-color: #041427;
border-top: 2px solid #a88d74;
`;

const SiteLinks = styled.div`
display: flex;
justify-content: center;
padding: 5px;
font-size: 12px;
&>a{
  text-decoration: none;
}
&>a:link{
  color:#a88d74;
}
&>a:visited{
  color:#a88d74;
}
`;

const License = styled.div`
padding: 10px;
font-size: 12px;
text-align: center;
border-top: 1px solid #a88d74;
`;
