import React, {FC} from 'react';
import styled from "styled-components";

interface Props {
  effects: ITftItemEffect[];
  className?: string;
}

enum TftItemEffectType {
  AD = 'attack_damage',
  AP = 'ability_power',
  AR = 'armor',
  AS = 'attack_speed',
  HP = 'health',
  MP = 'mana',
  MR = 'magic_resist',
  CT = 'critical',
  DG = 'dodge'
}

const ItemEffects: FC<Props> = ({effects, className}) => {
  return (<EffectBox className={className}>
    {effects.map(effect => {
      if (effect.type === TftItemEffectType.AD) {
        return (
          <Effect key={TftItemEffectType.AD}><img src="/assets/tft/icons/ad.png" alt="ad" height={15}/> +{effect.value}
          </Effect>)
      } else if (effect.type === TftItemEffectType.AP) {
        return (
          <Effect key={TftItemEffectType.AP}><img src="/assets/tft/icons/ap.png" alt="ap" height={15}/> +{effect.value}
          </Effect>)
      } else if (effect.type === TftItemEffectType.AR) {
        return (
          <Effect key={TftItemEffectType.AR}><img src="/assets/tft/icons/ar.png" alt="ar" height={15}/> +{effect.value}
          </Effect>)
      } else if (effect.type === TftItemEffectType.AS) {
        return (
          <Effect key={TftItemEffectType.AS}><img src="/assets/tft/icons/as.png" alt="as" height={15}/> +{effect.value}
          </Effect>)
      } else if (effect.type === TftItemEffectType.HP) {
        return (
          <Effect key={TftItemEffectType.HP}><img src="/assets/tft/icons/hp.png" alt="hp" height={15}/> +{effect.value}
          </Effect>)
      } else if (effect.type === TftItemEffectType.MP) {
        return (
          <Effect key={TftItemEffectType.MP}><img src="/assets/tft/icons/mana.png" alt="mana"
                                                  height={15}/> +{effect.value}
          </Effect>)
      } else if (effect.type === TftItemEffectType.MR) {
        return (
          <Effect key={TftItemEffectType.MR}><img src="/assets/tft/icons/mr.png" alt="mr" height={15}/> +{effect.value}
          </Effect>)
      } else if (effect.type === TftItemEffectType.CT) {
        return (<Effect key={TftItemEffectType.MR}><img src="/assets/tft/icons/cr.png" alt="mr" height={15}/> +{effect.value}
        </Effect>)
      } else if (effect.type === TftItemEffectType.DG) {
        return (<Effect key={TftItemEffectType.MR}><img src="/assets/tft/icons/dg.png" alt="mr" height={15}/> +{effect.value}
          </Effect>)
      }
      throw new Error('not found type');
    })}
  </EffectBox>)
};

export default ItemEffects;

const EffectBox = styled.div`
display: flex;
align-items: center;
`;

const Effect = styled.div`
display: flex;
align-items: center;
&+& {
  margin-left: 5px;
}
`;
