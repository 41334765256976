import React from 'react';
import {DndProvider} from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import Routes from "routes";

const App = () =>
  (<DndProvider backend={HTML5Backend}>
    <Routes/>
  </DndProvider>);

export default App;
