import Tooltip from "@components/modules/common/Tooltip";
import TftChampionDetail from "@components/modules/tft/ChampionDetail";
import React, {FC, SyntheticEvent, useCallback} from 'react';
import styled, {css} from "styled-components";
import {championImg} from "utils/image";

interface Props {
  champion: ITftChampion;
  selected?: boolean;
  size?: number;
  className?: string;
  tooltip?: boolean;
  onClick?: (champion: ITftChampion) => unknown
}

const TftChampionTooltip = ({
                              champion, size = 55, selected, className, tooltip = true, onClick = () => {
  }
                            }: Props) => {
  const handleOnClick = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onClick(champion);
  }, [onClick, champion]);
  return (<>
    {tooltip ? <ChampionTooltip {...champion}/> : false}
    <Button data-tip data-for={champion.id} data-tip-disable={!tooltip} size={size} selected={selected}
            className={className}
            onClick={handleOnClick}>
      <Img size={size} tier={champion.cost} selected={selected} src={championImg(champion.id)}
           alt={champion.name}/>
    </Button>
  </>);
};
export default TftChampionTooltip;

const ChampionTooltip: FC<ITftChampion> =
  (champion) =>
    (<TooltipChampion id={champion.id} effect="solid" border>
      <TftChampionDetail {...champion} />
    </TooltipChampion>);

const tierColor = (cost: number) => {
  switch (cost) {
    case 2:
      return 'green';
    case 3:
      return 'blue';
    case 4:
      return 'purple';
    case 5:
      return 'yellow';
    default:
      return 'gray'
  }
};

const TooltipChampion = styled(Tooltip)`
width: 370px;
`;

const Button = styled.button<{ size: number, selected?: boolean; }>`
width: ${props => props.size}px;
height: ${props => props.size}px;
${props => props.selected ? css`opacity: 0.3;` : null}
`;

const Img = styled.img<{ tier: number; size: number; selected?: boolean }>`
width: ${props => props.size}px;
height: ${props => props.size}px;
border: 1px solid ${props => props.selected ? 'gray' : tierColor(props.tier)};
`;
