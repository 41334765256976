import TftSimulatorLayout from "@components/templates/tft/TftSimulatorTemplate";
import {uniq} from 'lodash';
import React, {useCallback} from 'react';
import {RouteComponentProps} from "react-router";
import {generatePath, parsePath} from "utils/path";

type Props = RouteComponentProps<{ championCodes?: string }>;

const TftSimulatorPage = ({history, match: {params: {championCodes}}}: Props) => {
  const selects = parsePath(championCodes);
  const handleOnSelect = useCallback((champions: ITftChampion | ITftChampion[]) => Array.isArray(champions)
    ? history.push(`/tft/simulator/${generatePath(uniq(selects.concat(champions)))}`)
    : selects.includes(champions)
      ? history.push(`/tft/simulator/${generatePath(selects.filter(champion => champion.id !== champions.id))}`)
      : history.push(`/tft/simulator/${generatePath(uniq([...selects, champions]))}`), [history, selects]);
  return (<TftSimulatorLayout
    selects={selects}
    onSelect={(champion: ITftChampion | ITftChampion[]) => handleOnSelect(champion)}
    onReset={() => history.push('/tft/simulator')}
  />);
};
export default TftSimulatorPage;
