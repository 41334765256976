import TftSummonerDetailTemplate from "@components/templates/tft/TftSummonerDetailTemplate";
import {Rank} from "models/Rank";
import {Tier} from "models/Tier";
import React, {FC} from 'react';
import {RouteComponentProps, withRouter} from "react-router";

type Props = RouteComponentProps<{ name: string }>;

const TftSummonerDetailPage: FC<Props> = ({match: {params: {name}}}: Props) => {

  return <TftSummonerDetailTemplate
    name={name}
    wins={3}
    looses={5}
    rank={Rank.I}
    tier={Tier.BRONZE}
  />;
};

export default withRouter<Props, FC<Props>>(TftSummonerDetailPage);
